@tailwind base;
@tailwind components;

.btn {
    @apply px-9 py-2 font-medium block transition ease-in-out duration-300 bg-primary text-white rounded-md leading-snug whitespace-nowrap hover:bg-blue-900 text-base;
  }
  
  .btn.btn-sm {
    @apply px-4 py-2 text-sm;
  }
  
  .btn.btn-lg {
    @apply px-14 py-3;
  }
  
  .btn.btn-md {
    @apply px-9 py-2;
  }
  
  .btn.btn-pill {
    @apply rounded-full;
  }
  
  .btn-outline {
    @apply border-primary border rounded py-3 px-4 hover:bg-primary transition-all hover:text-white;
  }
  
  .btn-outline.btn-active {
    @apply bg-white border-primary text-primary;
  }
  .header-1 {
    @apply text-5xl leading-snug font-bold
  }
  .header-2 {
    @apply text-4xl font-semibold
  }
  .header-3 {
    @apply text-3xl font-semibold
  }
  
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 30, 30, 30;
    --background-start-rgb: 238, 238, 238;
    --background-end-rgb: 240, 240, 240;
  }
}

::-webkit-scrollbar {
  width: 4px; 
}

::-webkit-scrollbar-track {
  background: rgba(var(--background-start-rgb), 0.5);
}

::-webkit-scrollbar-thumb {
  background-color: #1848a0bc; 
  border-radius: 10px; 
}

::-webkit-scrollbar-thumb:hover {
  background-color: #1848a0; 
}
body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

